<template>
  <div style="background-color:#1890ff;height: 100%;align-items: center;justify-content: center;display: flex;">
    <div style="color: #fff;letter-spacing: 2px;display: inline-block;width:1680px;text-align: center;">
      <p style="font-size: 90px;margin-top:0;">招投研究院（广州）有限公司</p>
      <p style="font-size: 90px">
        <span>北京时间：</span><br/>
        <span>{{year}}年</span><span>{{month}}月</span><span>{{date}}日</span>
        <span style="margin: 0 15px;">{{weeks[weekIndex]}}</span>
        <span>{{hour}}时</span><span>{{minute}}分</span><span>{{second}}秒</span>
      </p>
			<div class="time">
					<clock :time="time" style="width: 230px;height: 230px;"></clock>
			</div>
    </div>
		
  </div>
</template>

<script>
	import clock from 'vue-clock2';
  export default {
		components: {clock},
    data() {
      return {
        year:'',
        month:'',
        date:'',
        hour:'',
        minute:'',
        second:'',
        weekIndex:'',
        weeks:[
          '星期日',
          '星期一',
          '星期二',
          '星期三',
          '星期四',
          '星期五',
          '星期六',
        ],
        differTime:['00','00','00','00'],
				time: '10:40'
      };
    },
    created() {
			this.clock();
		},
    methods: {
      clock(){
        var that=this;
        var date = new Date();
        this.year = date.getFullYear();
        this.month = (date.getMonth() + 1)>9?(date.getMonth() + 1):'0'+(date.getMonth() + 1);
        this.date = date.getDate()>9?date.getDate():'0'+date.getDate();
        this.weekIndex = date.getDay();
				
				let h = date.getHours();
				h = h < 10 ? ('0' + h) : h;
				this.hour = h;
				let m = date.getMinutes();
				m = m < 10 ? ('0' + m) : m;
				this.minute = m;
				let s = date.getSeconds();
				s = s < 10 ? ('0' + s) : s;
				this.second = s;
				this.time = h + ":" + m+":"+s;
        setTimeout(function(){that.clock()},1000)
      }
    },
  }
</script>
<style scoped>
 #diffTime span{
   color:#fadb14;
   font-weight: 550;
 }
</style>
<style>
	.time{
		margin-top: 50px;
	}
	.clock-hour,.clock-minute,.clock-second{
		background-color: #fff !important;
	}
</style>
